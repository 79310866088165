import { useSelector } from 'react-redux';
import factoryData from 'Store/Constants/newData';
import countries from 'Store/Constants/newBorders';

const keyToPropName = {
    'PRODUCT TYPES': 'product_type',
    SUPPLIER: 'supplier_name',
    COLLEGIATE: 'special_events',
    BRANDS: 'brand',
};

function filterExportDataByProp(exportView, excludeCountry = false) {
    return (factory) => {
        let shouldFilter = false;
        if (
            !excludeCountry &&
            exportView?.countryId &&
            exportView?.countryId !== factory?.geoData?.A3
        ) {
            shouldFilter = true;
        }

        if (exportView?.filters) {
            if (!shouldFilter) {
                const filterKeys = Object.keys(exportView);

                for (let i = 0; i < filterKeys.length; i += 1) {
                    const filterKey = filterKeys?.[i];
                    const filterData = exportView?.[filterKey] || [];
                    const aliasKey = keyToPropName?.[filterKey];

                    if (!shouldFilter && aliasKey && filterData.length) {
                        if (
                            !filterData.some(
                                (r) =>
                                    factory?.[aliasKey]
                                        ?.split(', ')
                                        ?.map((fD) => fD?.toUpperCase?.())
                                        .indexOf(r) >= 0,
                            )
                        ) {
                            shouldFilter = true;
                        }
                    }
                }
            }
        }

        return !shouldFilter;
    };
}

function buildFactoryData(exportView) {
    return (factoryData || [])
        .filter(filterExportDataByProp(exportView))
        .reduce((allFactories, factory) => {
            const resAllFactories = allFactories;
            if (factory?.contractor_id) {
                resAllFactories[factory.contractor_id] = factory;
            }

            return resAllFactories;
        }, {});
}

function buildFactoryDataOnlyFilters(exportView) {
    return (factoryData || [])
        .filter(filterExportDataByProp(exportView, true))
        .reduce((allFactories, factory) => {
            const resAllFactories = allFactories;
            if (factory?.contractor_id) {
                resAllFactories[factory.contractor_id] = factory;
            }

            return resAllFactories;
        }, {});
}

function buildCountryList(factories = {}) {
    return Object.values(factories).reduce((allCountries, factory) => {
        const resAllCountries = allCountries;
        const countryGeoJson = countries?.[factory.geoData.A3];
        if (factory?.geoData?.A3 && countryGeoJson) {
            resAllCountries[factory.geoData.A3] = countryGeoJson;
        }

        return resAllCountries;
    }, {});
}

function useFilters() {
    const {
        exportViewData = {},
        factoriesToRender = [],
        countriesToRender = {},
        onlyFilteredCountries = {},
        factoriesToRenderExportRead = [],
    } = useSelector((state) => {
        const exportView = state?.exportView || {};
        const factoriesToRenderRaw = buildFactoryData(exportView) || [];

        return {
            exportViewData: exportView,
            factoriesToRender: factoriesToRenderRaw,
            countriesToRender: buildCountryList(factoriesToRenderRaw),
            onlyFilteredCountries: buildCountryList(
                buildFactoryDataOnlyFilters(exportView) || [],
            ),
            factoriesToRenderExportRead: Object.values(
                factoriesToRenderRaw,
            ).map((fac) => {
                return {
                    ...fac,
                    geoData: null,
                };
            }),
        };
    });

    return {
        exportViewData,
        factoriesToRender,
        countriesToRender,
        onlyFilteredCountries,
        factoriesToRenderExportRead,
    };
}

export default useFilters;
